import { IntlEnum, Title } from '@common/components';
import { GridViewProvider } from '@common/context/GridView/useGridView';
import GridConciliation from '../components/GridConciliation/GridConciliation';


export default function ListConciliations() {
    return (
        <GridViewProvider>
            <Title>
                <IntlEnum name="reimbursement" value="conciliation"/>
            </Title>
            <GridConciliation/>
        </GridViewProvider>
    )
}
