import { Grid } from "@mui/material";
import {
  GridView, 
} from "@common/components";
import GridColumns from "@reimbursement/reimbursements/components/GridConciliation/GridColumns";
import { Conciliation } from "@reimbursement/reimbursements/contracts/Conciliation";
import { useConciliation } from "@reimbursement/reimbursements/hooks/useConciliation";
import { FilterTopReimbursement } from "@shared/components/FilterTopReimbursement";
import { ClearFilterButton } from "@shared/components/ClearFilterButton";

export default function GridConciliation() {

  const columns = GridColumns();

  const defaultFilterTop = {
    begin_date: null,
    end_date: null
  }

  
  const RenderGridConciliation = () => {
    return (
      <>
      <FilterTopReimbursement {...defaultFilterTop} />
      <ClearFilterButton />
      <Grid />
      <GridView<Conciliation>
          columns={columns}
          hook={useConciliation}
          paginated
          tableIdentifier="RE"
          hiddenColumns={["id"]}
      />
      </>
    )
  }

  return (
    <>
      <Grid />
      <RenderGridConciliation/>
    </>
  )
}
