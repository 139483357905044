import { useMemo } from "react";
import { Column } from "react-table";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  IntlEnum, 
  ColumnFilterValidated,
  IntlMoney,
} from "@common/components";
import { Reimbursement } from "@reimbursement/reimbursements/contracts/Reimbursement";
import { Link } from "react-router-dom";
export default function GridColumns() {

  const columns: Column<Reimbursement>[] = useMemo(() => [
    {
      Header: <IntlEnum name="reimbursement_grid" value="industry_name" />,
      accessor: "industryName",
      Filter: ColumnFilterValidated,
      width: '20%',
      isVisible: false
    },
    {
      Header: <IntlEnum name="reimbursement_grid" value="industry_code" />,
      accessor: "industryCode",
      Filter: ColumnFilterValidated,
      width: '35%',
      isVisible: false
    },
    {
      Header: <IntlEnum name="reimbursement_grid" value="predicted_value" />,
      accessor: "predictedValue",
      isVisible: false,
      Cell: ({ value }) => <IntlMoney value={value} />
    },
    {
      Header: <IntlEnum name="reimbursement_grid" value="period" />,
      accessor: "period",
      isVisible: false,
    },
    {
      Header: <IntlEnum name="reimbursement_grid" value="details" />,
      id: "icon",
      Cell: ({ row }: any) => (
        <Link to={`/reimbursement/reimbursements/conciliation/${row.original.industryCode}`}>
        <VisibilityIcon sx={{fill: "green"}}/>
        </Link>
      ),
    },
  ],[]);

  return columns;
}
