import { IRoute, RenderRoutes } from "@src/routes";
import batchRoutes from "@reimbursement/batch/routes";
import reimbursementRoutes from "@reimbursement/reimbursements/routes"

const mdRoutes: IRoute[] = [
  {
    menu: { label: "Monitoro Distribuidores" },
    path: "/reimbursement",
    key: "MD_ROOT",
    component: (props: any) => <RenderRoutes {...props} />,
    routes: [
      ...batchRoutes,
      ...reimbursementRoutes,
    ],
  },
];

export default mdRoutes;
