import { useQuery } from "react-query";
import { gql } from "graphql-request";
import { convertReimbursementResponseCursorPaginated } from "@shared/helpers/ConvertReimbuesementResponseCursorPaginated";
import { useReimbursementAPI } from "@common/hooks/useReimbursementAPI";
import { FilteredColumn } from "@common/components";
import { ConciliationStatus } from "../types/ConciliationStatus";
import { toDateTimeBrFormat } from "@common/helpers";
import { Conciliation } from "../contracts/Conciliation";
import { ConciliationData } from "../contracts/ConciliationData";
import { CursorPaginated } from "@types";
import { useParams } from "react-router-dom";

export interface ConciliationArgsFilterInput {
  industry?: {
    code?: string;
  }
  wholesaler?: {
    code?: string;
  }
  status?:ConciliationStatus[];
  startDate?: string;
  endDate?: string;
  fileUrl?: string;
}

interface UseConciliationOptions {
  before?: string,
  after?: string,
  first?: number,
  last?: number,
  limit: number,
  offset: number,
  filter?: ConciliationArgsFilterInput | undefined;
  gridFilters?: FilteredColumn[];
}

export function setFilters(options: ConciliationArgsFilterInput) {
  const filter: ConciliationArgsFilterInput =  options || {};

  return filter;
}

export function useConciliation(options : UseConciliationOptions){
  const { industry }: any = useParams();

  const filter: ConciliationArgsFilterInput = {
    industry: {
      code: industry
    }
  };

  options.limit = 10;
  options.offset = 0;
  options.filter = filter;

  if (options.gridFilters) {
    options.gridFilters.forEach((filteredColumn: FilteredColumn) => {
      if (filteredColumn.id === 'currentPage') {
        if(filteredColumn.value === 1) {
          options.offset = 0;
          return;
        }
        options.offset = (filteredColumn.value - 1) * (options.limit);
      }
      if (filteredColumn.id === 'status' && filteredColumn.value.length > 0) {
        options!.filter!.status = filteredColumn.value
        return;
      }
      if (filteredColumn.id === 'file') {
        options!.filter!.fileUrl = filteredColumn.value
        return;
      }
      if (filteredColumn.id === 'end_date') {
        options!.filter!.endDate = filteredColumn.value
        return;
      }
      if (filteredColumn.id === 'begin_date') {
        options!.filter!.startDate = filteredColumn.value
        return;
      }
    });
  }
  

  const { request } = useReimbursementAPI();

  return useQuery("conciliations: " + JSON.stringify(options), async () => {
      const { allRecords } = await request(
        gql`
        query allRecords(
          $filter: RecordFilterInput,
          $offset: Int
          $limit: Int
        )
        {
          allRecords(

            limit: $limit
            offset: $offset
            filters: $filter
          ) {
            edges {
              cursor
              node{
                id
                fileUrl
                industry{
                  code
                }
                startDate
                endDate
                status
                valueAccuracy
                quantityAccuracy
              }
            }
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
              pageCount
              totalCount
            }
          }
        }`, options
      );

      var result = {
        ...allRecords.pageInfo,
        data: allRecords.edges,
      }

      var paginationParams = {
        limit: options.limit,
        offset: options.offset,
      }

      const paginated  = convertReimbursementResponseCursorPaginated<any>(result, paginationParams);
      return refactorResult(paginated);
  }, {
    refetchOnWindowFocus: false
  });
}

export function refactorResult(data: CursorPaginated<ConciliationData>) {

  var formatedData: Conciliation[] = [];

  data.edges.map((d: ConciliationData) => {
    formatedData.push({
    id: d.node.id,
    file: d.node.fileUrl,
    industry_code : d.node.industry?.code || '',
    period : toDateTimeBrFormat(d.node.startDate) + " até " + toDateTimeBrFormat(d.node.endDate),
    status: d.node.status,
    value_accuracy: d.node.valueAccuracy || 0,
    quantity_accuracy: d.node.quantityAccuracy || 0,
    });
  });

  return {
    ...data,
    edges: formatedData,
  }
}
