import { IRoute } from "@src/routes";
import ListReimbursements from "./pages/ListReimbursementsPage";
import ListConciliations from "./pages/ListConciliationsPage";

const routes: IRoute[] = [
    {
        menu: { label: "Pedidos" },
        path: "/reimbursement/reimbursements",
        key: "ORDER_LIST",
        exact: true,
        component: ListReimbursements,
    },
    {
        menu: { label: "Pedidos" },
        path: "/reimbursement/reimbursements/conciliation/:industry",
        key: "ORDER_LIST",
        exact: true,
        component: ListConciliations,
    }
];

export default routes;
