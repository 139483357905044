import { IntlEnum, Title } from '@common/components';
import { GridViewProvider } from '@common/context/GridView/useGridView';
import GridReimbursement from '../components/GridReimbursement/GridReimbursement';


export default function ListReimbursements() {
    return (
        <GridViewProvider>
            <Title>
                <IntlEnum name="reimbursement" value="reimbursement"/>
            </Title>
            <GridReimbursement />
        </GridViewProvider>
    )
}
