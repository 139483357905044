import { useMemo } from "react";
import { Column } from "react-table";
import {
  IntlEnum, 
  ColumnFilterValidated,
} from "@common/components";
import { Conciliation } from "@reimbursement/reimbursements/contracts/Conciliation";
import { getValueEnumObject } from "@shared/helpers";
import { ConciliationStatus } from "@reimbursement/reimbursements/types/ConciliationStatus";
import { EnumConciliationStatus } from "@reimbursement/reimbursements/contracts/EnumConciliationStatus";
import useConciliationStatus, { convertSelectionOption } from "@reimbursement/reimbursements/hooks/useConciliationStatus";
import { MultiSelectStatusFilter } from "@common/components/MultiSelectStatusFilter/MultiSelectStatusFilter";
export default function GridColumns() {

  const statuses = useConciliationStatus();

  const columns: Column<Conciliation>[] = useMemo(() => [
    {
      Header: <IntlEnum name="conciliation_grid" value="id" />,
      accessor: "id",
      width: '20%',
    },
    {
      Header: <IntlEnum name="conciliation_grid" value="file" />,
      accessor: "file",
      Filter: ColumnFilterValidated,
      width: '20%',
    },
    {
      Header: <IntlEnum name="conciliation_grid" value="industry" />,
      accessor: "industry_code",
    },
    {
      Header: <IntlEnum name="conciliation_grid" value="period" />,
      accessor: "period",
    },
    {
      Header: <IntlEnum name="conciliation_grid" value="status" />,
      accessor: "status",
      Filter: MultiSelectStatusFilter,
      Cell: ({ value }) => getValueEnumObject<ConciliationStatus>(EnumConciliationStatus, value),
      options: convertSelectionOption(statuses),
    },
    {
      Header: <IntlEnum name="conciliation_grid" value="value_accuracy" />,
      accessor: "value_accuracy",
      Cell: ({ value }) => <>{value} %</>
    },
    {
      Header: <IntlEnum name="conciliation_grid" value="quantity_accuracy" />,
      accessor: "quantity_accuracy",
      Cell: ({ value }) => <>{value} %</>
    },
  ],[]);

  return columns;
}
