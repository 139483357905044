import { Grid } from "@mui/material";
import {
  GridView, 
} from "@common/components";
import GridColumns from "@reimbursement/reimbursements/components/GridReimbursement/GridColumns";
import { Reimbursement } from "@reimbursement/reimbursements/contracts/Reimbursement";
import { useReimbursements } from "@reimbursement/reimbursements/hooks/useReimbursements";

export default function GridReimbursement() {
  const columns = GridColumns();
  const RenderGridReimbursement = () => {
    return (
      <GridView<Reimbursement>
          columns={columns}
          hook={useReimbursements}
          paginated
          tableIdentifier="RE"
      />
    )
  }

  return (
    <>
      <Grid />
      <RenderGridReimbursement/>
    </>
  )
}
