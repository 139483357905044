import { useQuery } from "react-query";
import { gql } from "graphql-request";
import { FilteredColumn } from "@common/components";
import { SortFieldType } from "@ca/orders/types/SortField";
import { Batch } from "../contracts/Batch";
import { CursorPaginated } from "@types";
import { toDateTimeBrFormat } from "@common/helpers";
import { convertReimbursementResponseCursorPaginated } from "@shared/helpers/ConvertReimbuesementResponseCursorPaginated";
import { useReimbursementAPI } from "@common/hooks/useReimbursementAPI";
import { ReimbursementBatches } from "../contracts/ReimbursementBatches";
import { ReimbursementStatus } from "../types/ReimbursementStatus";

export interface ReimbursementsArgsFilterInput {
  wholesaler_code?: string,
  industry?: {
    codes?: string[];
  }
  status?: ReimbursementStatus;
  startDate?: string;
  endDate?: string;
}

interface UseReimbursementsOptions {
  before?: string,
  after?: string,
  first?: number,
  last?: number,
  limit: number,
  offset: number,
  filter?: ReimbursementsArgsFilterInput | undefined;
  gridFilters?: FilteredColumn[];
}

export interface SortFields {
  field: SortFieldType,
  direction: 'ASC' | 'DESC'
}

export function setFilters(options: ReimbursementsArgsFilterInput) {
  const filter: ReimbursementsArgsFilterInput =  options || {};

  return filter;
}

export function useReimbursements(options: UseReimbursementsOptions){

  const filter: ReimbursementsArgsFilterInput = {};

  options.offset = 0;
  options.limit = 10;
  options.filter = filter;

  if (options.gridFilters) {
    options.gridFilters.forEach((filteredColumn: FilteredColumn) => {
      if (filteredColumn.id === 'currentPage') {
          if(filteredColumn.value === 1) {
            options.offset = 0;
            return;
          }
          options.offset = (filteredColumn.value - 1) * options.limit;
      }
      if (filteredColumn.id === 'industry_code' && filteredColumn.value.length > 0) {
        options!.filter!.industry! = {
          codes: filteredColumn.value
        }
          return;
      }
      if (filteredColumn.id === 'status' && filteredColumn.value.length > 0) {
        options!.filter!.status = filteredColumn.value
        return;
      }
      if (filteredColumn.id === 'end_date') {
        options!.filter!.endDate = filteredColumn.value
        return;
      }
      if (filteredColumn.id === 'begin_date') {
        options!.filter!.startDate = filteredColumn.value
        return;
      }
    });
  }
  
  const { request } = useReimbursementAPI();

  return useQuery("reimbursements:" + JSON.stringify(options), async () => {
      const { allReimbursements } = await request(
        gql`
        query allReimbursements(
            $filter: ReimbursementFilterInput,
            $offset: Int
            $limit: Int
        ) {
        allReimbursements(
        filters: $filter
        offset: $offset
        limit: $limit
        ) {
          edges {
            cursor
            node {
              id
              externalId
              startDate
              endDate
              value
              deduction
              industry{
                code
                name
              }
              status
              visited
              division{
                id
                name
              }
              customerRating{
                id
                name
              }
              user {
                name
              }
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            pageCount
            totalCount
          }
        }
        }`, options
      );

      var result = {
        ...allReimbursements.pageInfo,
        data: allReimbursements.edges,
      }

      var paginationParams = {
        limit: options.limit,
        offset: options.offset,
      }

      const paginated  = convertReimbursementResponseCursorPaginated<ReimbursementBatches>(result, paginationParams);
      return refactorResult(paginated);
  }, {
    refetchOnWindowFocus: false
  });
}

export function refactorResult(data: CursorPaginated<ReimbursementBatches>) {

  var formatedData: Batch[] = [];

  data.edges.map((d: ReimbursementBatches) => {
    formatedData.push({
    id: d.node.id,
    externalId: d.node.externalId,
    industry_code : d.node.industry?.code || '',
    industry_name : d.node.industry?.name || '',
    status: d.node.status,
    division: d.node.division?.name || '',
    visited: d.node.visited,
    rating: d.node.customerRating?.name || '',
    predicted_value : d.node.value,
    deduction: d.node.deduction,
    period : toDateTimeBrFormat(d.node.startDate) + " até " + toDateTimeBrFormat(d.node.endDate),
    user: d.node?.user?.name || '',
    });
  });

  return {
    ...data,
    edges: formatedData,
  }
}
