import { useMemo } from "react";
import { ConciliationStatus } from "../types/ConciliationStatus";
import { EnumConciliationStatus } from "../contracts/EnumConciliationStatus";

export function convertSelectionOption(status: ConciliationStatus[]) {
  return status.map((status: any) => Object({value: status.code, description: status.name}));
}

export type StatusOption = {
  code:ConciliationStatus
  name: string
}

export default function useConciliationStatus(): any[] {
  return useMemo(
    () => [   
      {
        code: 'AWAITING',
        name: "Aguardando Processamento"
      },
      {
        code: 'PROCESSING',
        name: "Em Processamento"
      },
      {
        code: 'DONE',
        name: "Concluído"
      },
      {
        code: 'FAILED',
        name: "Falha"
      },
    ], []);
}