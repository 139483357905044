import { TextField } from "@common/components/Form";
import { Autocomplete, Stack } from '@mui/material';
import { useCallback, useState } from "react";

export function MultiSelectStatusFilterCA({column: { options, filterValue, setFilter, customInput },}: any) {
  const [defaultValueInput, setDefaultValueInput] = useState<any>(filterValue)
  
  const defaultOptions = filterValue?.map((item: string) => {
    return options.filter((opt: any) => {
      return opt.value === item
    })[0]
  })

  const setValue = useCallback((value) => {
    setDefaultValueInput(value)
    setFilter(value)
  }, [setFilter])

  return (
    <Stack spacing={2} sx={{ width: 250 }}>
    <Autocomplete
      multiple
      freeSolo={customInput}
      limitTags={1}
      size="small"
      id="tags-standard"
      options={options}
      getOptionLabel={(option:any) => {
        return option.description
      }}
      value={defaultOptions}
      onChange={(event, value)=> {
        const optValue = value.map((oValue) => {
          return oValue
        })
        
        let filters = customInput === undefined ? optValue.map(({value}) => value) : optValue;

        setValue(filters);
      }} 

      renderInput={(params) => (
        <TextField           
          {...params}
          value={filterValue || ""}
          variant="standard"
        />
      )}
    />
  </Stack>
  );
}
