import { convertReimbursementResponseCursorPaginated } from "@shared/helpers/ConvertReimbuesementResponseCursorPaginated";
import { Reimbursement } from "../contracts/Reimbursement";

export function useReimbursements(){
  
  let data = {
    hasPreviousPage: false,
    hasNextPage: false,
    currentPage: 1,
    lastPage: 1,
    perPage: 10,
    startCursor: 1,
    endCursor: 3,
    pageCount: 3,
    totalCount: 3,
    data: [
        {
          industryName: 'Teste1',
          industryCode: 'TEST1',
          period: '01/04/2024 a 21/04/2024',
          predictedValue: 10,
        },
        {
          industryName: 'Teste2',
          industryCode: 'TEST2',
          period: '10/04/2024 a 28/04/2024',
          predictedValue: 20,
        },
        {
          industryName: 'Teste3',
          industryCode: 'TEST3',
          period: '15/04/2024 a 17/04/2024',
          predictedValue: 30,
        },
      ]
  }

  var paginationParams = {
    limit: 10,
    offset: 0,
  }

  const paginated  = convertReimbursementResponseCursorPaginated<Reimbursement>(data, paginationParams);
  
  return {
    data: paginated,
    edges: paginated.edges
  };
}