import { Link } from 'react-router-dom';
import { Button } from './Form/Button';
import logo from '@assets/images/funcional.svg';
import { useJwtUser } from '@common/hooks';
import { IntlEnum } from '@common/components';

const menus = [
  {
    menu: { label: <IntlEnum name="menu_monitoro" value="menu_monitoro"/> },
    path: '/',
    roles: ['master', 'wholesaler', 'wholesaler_branch'],
    routes: [
            {
        menu: { label: <IntlEnum name="menu_monitoro" value="wholesalers"/> },
        path: '/we/orders',
        roles: ['master', 'wholesaler', 'wholesaler_branch']
      },
      {
        menu: { label: <IntlEnum name="menu_monitoro" value="canal_autorizador"/> },
        path: '/ca/orders',
        roles: ['master', 'wholesaler', 'wholesaler_branch']
      },
      {
        menu: { label: <IntlEnum name="menu_monitoro" value="reimbursement_batches"/> },
        path: '/reimbursement/batches',
        id:'reimbursement',
        roles: ['master', 'wholesaler', 'wholesaler_branch']
      },
    ]
  },
];

const menuToggle = process.env.REACT_APP_ENABLE_REIMBURSEMENT_MENU ? process.env.REACT_APP_ENABLE_REIMBURSEMENT_MENU === "true" : false

if(menuToggle) {
  menus[0].routes.push(
    {
      menu: { label: <IntlEnum name="menu_monitoro" value="reimbursement"/> },
      path: '/reimbursement/reimbursements',
      id:'reimbursement',
      roles: ['master', 'wholesaler', 'wholesaler_branch']
    }
  );
}

export function NavBar() {
  const { user, logout } = useJwtUser();

  return (
    <>
      <aside className='flex-shrink-0 hidden w-64 bg-white border-r dark:border-primary-darker dark:bg-darker md:block'>
        <div className='px-3 pt-4'>
          <Link to='/'>
            <img src={logo} alt='Funcional' className='max-w-full w-48' />
          </Link>
        </div>
        <ul>
          {menus.map((route) =>
            user && user.hasAnyOfTheseSsoRoles(...route.roles) ? (
              <li className='mt-8' key={route.path}>
                <h5 className='px-3 mb-3 lg:mb-3 uppercase tracking-wide font-semibold text-sm lg:text-xs text-gray-900'>
                  {route?.menu?.label}
                </h5>
                <ul>
                  {route.routes.map((subRoute) =>
                    user && user.hasAnyOfTheseSsoRoles(...subRoute.roles) ? (
                      <li key={subRoute.path}>
                        <Link
                          className='px-3 py-2 transition-colors duration-200 relative block hover:text-gray-900 text-gray-500'
                          to={subRoute.path}
                        >
                          <span className='rounded-md absolute inset-0 bg-cyan-50 opacity-0'></span>
                          <span className='relative'>
                            {subRoute?.menu?.label}
                          </span>
                        </Link>
                      </li>
                    ) : null
                  )}
                </ul>
              </li>
            ) : null
          )}
        </ul>

        <div className='mt-4 p-4'>
          <Button className='w-full' onClick={() => logout()}>
            Sair
          </Button>
        </div>
      </aside>
    </>
  );
}
